export enum ETypeProduct {
  /** Nhà mặt tiền */
  townHouse,
  /** Nhà trong hẻm */
  alleyHouse,
  /** Nhà trọ/ dãy trọ */
  boardingHouse,
  /** Nhà đất/ nhà biệt lập */
  detachedHouse,
  /** Căn hộ/ chung cư */
  apartment,
  villa,
}

export enum EStatusProduct {
  DELETE = -1,
  SALE = 1,
  SOLD = 2,
  OWER_SOLD = 3,
  STOP_SALE = 4,
  OTHER_SOLD = 5
}
export enum EStatusEmployee {
  DELETE = -1,
  DEACTIVE = 0,
  ACTIVE = 1,
}

export enum EPriceCategory {
  /** Dưới 3 tỷ */
  under3B,
  /** Từ 3-5 tỷ */
  from3B_5B,
  /** Từ 5-10 tỷ */
  from5B_10B,
  /** Từ 10-20 tỷ */
  from10B_20B,
  /** Từ 20-30 tỷ */
  from20B_30B,
  /** Từ 30-50 tỷ */
  from30B_50B,
  /** Trên 50 tỷ */
  over50B,
}

export enum EAcreageCategory {
  /** Dưới 50m2 */
  under50m2,
  /** Từ 50-100m2 */
  from50_100m2,
  /** Từ 100-200m2 */
  from100_200m2,
  /** Từ 200-300m2 */
  from200_300m2,
  /** Từ 300-500m2 */
  from300_500m2,
  /** Từ 500-1000m2 */
  from500_1000m2,
  /** Trên 1000m2 */
  over1000m2,
}

export enum EDirectionType {
  East = 0,//đông
  South = 1,//nam
  North = 2,//bắc
  West = 3,//Tây
  SouthEast = 4,//Đông Nam

  SouthWest = 5,//Tây nam

  NorthWest = 6,//Tây Bắc

  NorthEast = 7,//Đông bắc
}

export enum ERecProfileStatus {
  DELETE = 0,
  NEW = 100,
  CONTACT = 200,
  PASS = 300,
  REJECT = 400,
}