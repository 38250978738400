import React, { useState, useEffect, useCallback } from 'react'
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import './index.css'
import 'leaflet/dist/leaflet.css';
import vietnamDetailJson from "../../Data/diaphanhuyen.json";
import markerIcon from '../../Data/marker-icon.png';
import ReactDOMServer from "react-dom/server";
import { FullscreenControl } from "react-leaflet-fullscreen";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import * as L from "leaflet";
import 'leaflet-geosearch/dist/geosearch.css';
import MarkerClusterGroup from "react-leaflet-cluster"
import moment from 'moment';
import { EStatusProduct } from 'helpers/enums';
import {
    TypeProducts,
    DirectionTypes
} from 'helpers/config';
import { isMobile } from 'react-device-detect';
import { locationIcon, acreageIcon, currencyIcon, widthHeigh, feeService } from '../../utils/icon'
import * as Utils from './../../utils/env';
import { FiHeart } from 'react-icons/fi';

import {
    IProduct
} from 'interfaces';

const apiDataDummy: { [key: string]: { name: string; value: number } } = {
    "VN-26": { name: "Thừa Thiên-Huế", value: 1 },
    "VN-39": { name: "Đồng Nai", value: 1 },
    "VN-CT": { name: "Cần Thơ", value: 3 },
    "VN-DN": { name: "Đà Nẵng", value: 1 },
    "VN-HN": { name: "Hà Nội", value: 18 },
    "VN-SG": { name: "Hồ Chí Minh", value: 20 }
};


const SearchControl = (props) => {
    const { provider } = props
    const map = useMap();

    L.tileLayer('//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);
    // const provider = new OpenStreetMapProvider();
    let markIcon = new L.Icon({
        iconUrl: require('../../Data/marker-icon.png'),
        iconSize: [38, 38]
    })

    useEffect(() => {
        // provider.search({ query: search })
        const searchControl = GeoSearchControl({
            provider,
            style: 'bar',
            notFoundMessage: 'Sorry, that address could not be found.',
            autoComplete: true,         // optional: true|false  - default true
            autoCompleteDelay: 250,
            marker: {
                icon: markIcon,
                draggable: false,
            }
        });

        map.addControl(searchControl);

        return () => { map.removeControl(searchControl) };
    }, []);

    return null;
}

interface IMapProps {
    products: IProduct[]
}
const MapCompontent = (props: IMapProps) => {
    const { products } = props
    const [search, setSearch] = useState("")
    const prov = new OpenStreetMapProvider();
    const customIcon = new L.Icon({
        iconUrl: markerIcon,
        iconSize: [38, 38],
        iconAnchor: [12, 41]
    })
    const onEachFeatureHandler = useCallback((feature, layer) => {
        if (!feature?.properties?.code) throw new Error('No code available');

        const currentArea = apiDataDummy[feature?.properties?.code];
        if (typeof currentArea !== "undefined") {
            layer.setStyle({
                fillColor: currentArea.value > 3 ? "#FFB8E4" : "#781954"
            });
            const tooltipContent = ReactDOMServer.renderToString(
                <div className="tooltip">
                    <p className="title">{currentArea.name}</p>
                    <p className="amount">
                        <span>Số lượng bệnh viện </span>
                        <span>{currentArea.value}</span>
                    </p>
                </div>
            );
            layer.bindTooltip(tooltipContent);
        }
    }, []);

    const renderCardPopup = (product, isMobile) => {
        if (!product)
            return <></>

        const defaultImage = 'https://techland-test.hn.ss.bfcplatform.vn/master/logo/apple-touch-icon.png'
        const renderLocation = () => {
            //if (!product.address)
            return <div className='d-flex items-center'>
                <span className='d-flex align-items-center'> {locationIcon(24, 24)}: </span>
                &nbsp;
                <span className='text-gray-400 font-bold ml-1 text-14'>
                    <a onClick={e => e.stopPropagation()} target='_blank' href={'https://www.google.com/maps/dir/?api=1&destination=' + (product.address?.street + ", " + product.address?.wardName + ", " + product.address?.districtName + ', ' + product.address?.cityName)}>{product.address?.cityName ? product.address?.wardName + ", " + product.address?.districtName + ', ' + product.address?.cityName : 'Hồ Chí Minh, Việt Nam'}
                    </a>
                </span>
            </div>
        }

        const renderLandArea = () => {
            if (!product.acreage)
                return <></>
            return <div><div className='d-flex items-center product-acreage'>
                <span className='d-flex'>{acreageIcon()} &nbsp;:</span> &nbsp;
                <span className='product-acreage'>{product.acreage} m² </span>
                <span className='d-flex ml-3'>{widthHeigh()} &nbsp;:</span> &nbsp;
                <span className='product-acreage'>{product.width}x{product.height} m</span>
            </div>

            </div>
        }

        const renderPrice = () => {
            if (!product.price)
                return <></>
            return <div className='product-price d-flex items-center' >
                <span className='d-flex'>{currencyIcon()} &nbsp;:</span> &nbsp;
                {product.priceSale ?
                    <span>
                        <span className='' style={{ textDecoration: 'line-through' }}>{Utils.convertMilionToBilion(product.price, true)}</span>
                        <span className=''>{Utils.convertMilionToBilion(product.priceSale, true)}</span>
                    </span>
                    :
                    <span className=''>{Utils.convertMilionToBilion(product.price, true)}</span>
                }


                <span className='d-flex ml-1 mr-1'>{feeService()} :</span>
                {
                    (product.fee || product.feePercent) ?
                        <span>
                            <span className=''>{product.fee ? Utils.convertMilionToBilion(product.fee, true) : product.feePercent + "%"} </span>

                        </span>
                        :
                        <span className=''>{"TL"}</span>
                }
            </div>
        }
        const renderInfo = () => {
            return <div>
                <div className='product-price d-flex items-center' style={{ fontSize: "13px" }} >
                    {product.directionType != null ? <span className=''>{"Hướng: " + (DirectionTypes.find(a => a.value == product.directionType)?.label)}</span> : <span></span>}
                    {product.typeProduct != null ? <span className='ml-3'>{"Loại: " + (TypeProducts.find(a => a.value == product.typeProduct)?.label)}</span> : <span></span>}

                </div>
            </div>
        }
        const _renderStatusTag = () => {
            const diffDays = moment().diff(product?.updatedAt, 'days');
            if (diffDays <= 3 && product.status == EStatusProduct.SALE) {
                return <div className="status">Mới</div>;
            }
            if (product.status > EStatusProduct.SALE) {
                return <div className="sold">Đã bán</div>;
            }
            return null;
        };

        let styleMode = isMobile == true ? "card-mobile" : "card-mobile card-mobile-pc";
        return <div>
            <div style={{}} className={`${styleMode} restore-${product.id} bg-white   rounded-lg `}>
                <div className='icon-save-product'
                    onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}><FiHeart stroke='black' style={{ width: '20px', height: '20px' }} fill={product.isSaved == true ? 'red' : 'none'}></FiHeart></div>
                {_renderStatusTag()}
                {isMobile == true ?
                    <div className="content-wrapper">

                        <div className='d-flex justify-content-between row gy-3'>
                            <div className='col-lg-8 col-8'>
                                <div
                                    className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
                                >
                                    <span className="d-flex items-center font-bold ml-1 text-16">
                                        {product.employee?.name}
                                    </span>
                                </div>
                                <div className="product-name dark:text-white">
                                    {product.name}
                                </div>
                                {renderLandArea()}
                                {renderPrice()}
                                {(isMobile == true && product.property != null) ? <div className='product-price d-flex items-center' style={{ fontSize: '13px' }}><span className=''>{"Hiện trạng: " + product.property}</span> </div> : <span></span>}
                                {/* {renderUpdate()} */}


                            </div>

                            <div className='col-lg-4 col-3 img-wrapper'>
                                <img className="image" src={product.produtMediaFiles?.length > 0 ? product.produtMediaFiles[0]?.url : defaultImage} alt="" />
                            </div>
                            <div className='col-lg-12 col-12' style={{ marginTop: '0px' }}>
                                {renderInfo()}
                            </div>
                        </div>
                        {renderLocation()}
                    </div> :
                    <div className="content-wrapper">

                        <div className='d-flex justify-content-between row gy-3'>
                            <div className='col-lg-4 col-3 img-wrapper'>
                                <img className="image" src={product.produtMediaFiles?.length > 0 ? product.produtMediaFiles[0]?.url : defaultImage} alt="" />
                            </div>
                            <div className='col-lg-12 col-12' style={{ marginBottom: '0px', marginTop: '0px' }}>
                                <div
                                    className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
                                >
                                    <span className="d-flex items-center font-bold ml-1 text-16">
                                        {product.employee?.name}
                                    </span>
                                </div>
                                <div className="product-name dark:text-white">
                                    {product.name}
                                </div>
                                {renderLandArea()}
                                {renderPrice()}
                                {renderInfo()}
                                {(product.property != null) ? <div className='product-price d-flex items-center' style={{ fontSize: '13px' }}><span className=''>{"Hiện trạng: " + product.property}</span> </div> : <span></span>}
                                {/* {renderUpdate()} */}
                            </div>

                        </div>
                        {renderLocation()}
                    </div>
                }
            </div>
        </div >
    }
    const customPopup = (product) => {
        return (
            <Popup>
                {renderCardPopup(product, false)}
            </Popup>
        )
    }

    return <div>
        {/* <GeoLocation /> */}

        <div id="mapid">
            <MapContainer
                center={[10.8292376, 106.8193822]}
                zoom={13}
                scrollWheelZoom={true}
                // style={{ width: 700, height: 700 }}
                // zoom={6}
                // center={[16.047079, 108.20623]}
                // center={[10.84358, 106.79977]}
                // boxZoom={false}
                // maxZoom={13}
                closePopupOnClick={false}
                // dragging={false}
                trackResize={false}
            // touchZoom={false}
            // scrollWheelZoom={false}
            >
                <FullscreenControl forceSeparateButton={true} position="topright" />
                {/* <SearchControl
                    provider={prov}
                    showMarker={true}
                    showPopup={true}
                    popupFormat={({ query, result }) => result.label}
                    maxMarkers={3}
                    retainZoomLevel={false}
                    animateZoom={true}
                    autoClose={false}
                    searchLabel={"Enter address, please"}
                    keepResult={true}
                /> */}
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {/* <GeoJSON
                    data={(vietnamDetailJson as any).features}
                    style={{
                        // color: "#ebdfe6",
                        // weight: 1,
                        // fillColor: "#CBA7BD",
                        // fillOpacity: 1
                    }}
                    onEachFeature={() => { }}

                // onEachFeature={onEachFeatureHandler}
                /> */}
                <MarkerClusterGroup chunkedLoading>
                    {products?.map(product => {
                        if (!product || !product.address || !product.address.latitude || !product.address.longitude)
                            return <></>

                        return <Marker position={[product.address.latitude, product.address.longitude]} icon={customIcon}>
                            {customPopup(product)}
                        </Marker>
                    })}
                </MarkerClusterGroup>

                {/* <Popup
                    key="Hoang Sa Island"
                    position={[16.668011, 109.939995]}
                    autoClose={false}
                >
                    Hoàng Sa
                </Popup>
                <Popup
                    key="Truong Sa Island"
                    position={[10.487044, 113.250166]}
                    autoClose={false}
                >
                    Trường Sa
                </Popup> */}
            </MapContainer>
        </div>
    </div>
}

export default MapCompontent;