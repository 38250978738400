import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import './index.css'
import { IProduct } from 'interfaces';
import { EStatusProduct } from 'helpers/enums';
import { locationIcon, acreageIcon, currencyIcon, widthHeigh, feeService } from '../../utils/icon'
import * as Utils from './../../utils/env';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import {
    AcreageCategory,
    DEFAULT_PRODUCT_FILTER,
    PriceCategory,
    TypeProducts,
    DirectionTypes,
    billion,
} from 'helpers/config';
import { FiShoppingBag, FiEdit, FiPieChart, FiBarChart, FiCreditCard, FiStar, FiShoppingCart, FiHeart } from 'react-icons/fi';
interface IProps {
    product: IProduct,
    page?: number,
    setDataToLocalStorage?: Function
    setProductSaved?: Function
    query?: any,
    isMobile?: boolean
}

export const CardMobile = (props: IProps) => {
    const { product, page, setDataToLocalStorage, query, setProductSaved } = props
    const defaultImage = 'https://techland-test.hn.ss.bfcplatform.vn/master/logo/apple-touch-icon.png'
    useEffect(() => {

    }, []);
    const renderLocation = () => {
        //if (!product.address)
        return <div className='d-flex items-center'>
            <span className='d-flex align-items-center'> {locationIcon(24, 24)}: </span>
            &nbsp;
            <span className='text-gray-400 font-bold ml-1 text-14'>
                <a onClick={e => e.stopPropagation()} target='_blank' href={'https://www.google.com/maps/dir/?api=1&destination=' + (product.address?.street + ", " + product.address?.wardName + ", " + product.address?.districtName + ', ' + product.address?.cityName)}>{product.address?.cityName ? product.address?.wardName + ", " + product.address?.districtName + ', ' + product.address?.cityName : 'Hồ Chí Minh, Việt Nam'}
                </a>
            </span>
        </div>
    }

    const renderLandArea = () => {
        if (!product.acreage)
            return <></>
        return <div><div className='d-flex items-center product-acreage'>
            <span className='d-flex'>{acreageIcon()} &nbsp;:</span> &nbsp;
            <span className='product-acreage'>{product.acreage} m² </span>
            <span className='d-flex ml-3'>{widthHeigh()} &nbsp;:</span> &nbsp;
            <span className='product-acreage'>{product.width}x{product.height} m</span>
        </div>

        </div>
    }

    const renderPrice = () => {
        if (!product.price)
            return <></>
        return <div className='product-price d-flex items-center' >
            <span className='d-flex'>{currencyIcon()} &nbsp;:</span> &nbsp;
            {product.priceSale ?
                <span>
                    <span className='' style={{ textDecoration: 'line-through' }}>{Utils.convertMilionToBilion(product.price, true)}</span>
                    <span className=''>{Utils.convertMilionToBilion(product.priceSale, true)}</span>
                </span>
                :
                <span className=''>{Utils.convertMilionToBilion(product.price, true)}</span>
            }


            <span className='d-flex ml-1 mr-1'>{feeService()} :</span>
            {
                (product.fee || product.feePercent) ?
                    <span>
                        <span className=''>{product.fee ? Utils.convertMilionToBilion(product.fee, true) : product.feePercent + "%"} </span>

                    </span>
                    :
                    <span className=''>{"TL"}</span>
            }
        </div>
    }
    const renderUpdate = () => {
        if (!product.price)
            return <></>
        return <div className='product-price d-flex items-center mt-2' style={{ fontSize: "13px", position: 'absolute', left: '10px', top: '0px', color: 'white', background: '#F2BD27', padding: '2px' }} >
            <span className=''>{Utils.getDateShortUTC(product.updatedAt)}</span>
        </div>
    }

    const renderInfo = () => {
        return <div>
            <div className='product-price d-flex items-center' style={{ fontSize: "13px" }} >
                {product.directionType != null ? <span className=''>{"Hướng: " + (DirectionTypes.find(a => a.value == product.directionType)?.label)}</span> : <span></span>}
                {product.typeProduct != null ? <span className='ml-3'>{"Loại: " + (TypeProducts.find(a => a.value == product.typeProduct)?.label)}</span> : <span></span>}

            </div>
        </div>
    }

    const _renderStatusTag = () => {
        const diffDays = moment().diff(product?.updatedAt, 'days');
        if (diffDays <= 3 && product.status == EStatusProduct.SALE) {
            return <div className="status">Mới</div>;
        }
        if (product.status > EStatusProduct.SALE) {
            return <div className="sold">Đã bán</div>;
        }
        return null;
    };
    const _getStatusTag = () => {
        const diffDays = moment().diff(product?.updatedAt, 'days');
        if (diffDays <= 3 && product.status == EStatusProduct.SALE) {
            return 1;
        }
        if (product.status > EStatusProduct.SALE) {
            return 2;
        }
        return null;
    };
    const _renderDateTag = () => {
        var status = _getStatusTag();
        return <div className="createdat" style={{ right: status == null ? '5px' : (status == 1 ? '55px' : '65px') }}>{Utils.getDateShort(product.updatedAt, "DD-MM-YYYY HH:mm")}</div>;
    };
    const renderFee = () => {
        if (!product.price)
            return <></>
        return <div className='product-price d-flex items-center mt-2' style={{ fontSize: "13px" }} >
            <span className='d-flex'>{"Phí: "}</span> &nbsp;
            <span className=''>{Utils.getDateShortUTC(product.updatedAt)}</span>
        </div>
    }

    if (!product)
        return <></>
    let styleMode = isMobile == true ? "card-mobile" : "card-mobile card-mobile-pc";

    return (
        < Link to={{
            pathname: `/admin/product/${product.id}`
        }}
            state={{ id: product.id, page: page, query: query }}
            style={{ textDecoration: 'none' }}
            onClick={() => setDataToLocalStorage()}
        >

            <div style={{}} className={`${styleMode} restore-${product.id} bg-white border  rounded-lg shadow dark:bg-gray-800 dark:border-gray-700`}>
                <div className='icon-save-product' onClick={(e) => { e.stopPropagation(); e.preventDefault(); setProductSaved(product.id); }}><FiHeart stroke='black' style={{ width: '20px', height: '20px' }} fill={product.isSaved == true ? 'red' : 'none'}></FiHeart></div>
                {_renderStatusTag()}
                {_renderDateTag()}
                {props.isMobile == true ?
                    <div className="content-wrapper">

                        <div className='d-flex justify-content-between row gy-3'>
                            <div className='col-lg-8 col-8'>
                                <div
                                    className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
                                >
                                    {/* <img
                                className="rounded-full w-16 h-16"
                                src={avatar}
                                alt="user-profile"
                            /> */}
                                    <span className="d-flex items-center font-bold ml-1 text-16">
                                        {product.employee?.name}
                                    </span>
                                </div>
                                <div className="product-name dark:text-white">
                                    {product.name}
                                </div>
                                {renderLandArea()}
                                {renderPrice()}
                                {(props.isMobile == true && product.property != null) ? <div className='product-price d-flex items-center' style={{ fontSize: '13px' }}><span className=''>{"Hiện trạng: " + product.property}</span> </div> : <span></span>}
                                {/* {renderUpdate()} */}


                            </div>

                            <div className='col-lg-4 col-3 img-wrapper'>
                                <img className="image" src={product.produtMediaFiles?.length > 0 ? product.produtMediaFiles[0]?.url : defaultImage} alt="" />
                            </div>
                            <div className='col-lg-12 col-12' style={{ marginTop: '0px' }}>
                                {renderInfo()}
                            </div>
                        </div>
                        {renderLocation()}
                    </div> :
                    <div className="content-wrapper">

                        <div className='d-flex justify-content-between row gy-3'>
                            <div className='col-lg-4 col-3 img-wrapper'>
                                <img className="image" src={product.produtMediaFiles?.length > 0 ? product.produtMediaFiles[0]?.url : defaultImage} alt="" />
                            </div>
                            <div className='col-lg-12 col-12' style={{ marginBottom: '0px', marginTop: '0px' }}>
                                <div
                                    className="flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg"
                                >
                                    <span className="d-flex items-center font-bold ml-1 text-16">
                                        {product.employee?.name}
                                    </span>
                                </div>
                                <div className="product-name dark:text-white">
                                    {product.name}
                                </div>
                                {renderLandArea()}
                                {renderPrice()}
                                {renderInfo()}
                                {(product.property != null) ? <div className='product-price d-flex items-center' style={{ fontSize: '13px' }}><span className=''>{"Hiện trạng: " + product.property}</span> </div> : <span></span>}
                                {/* {renderUpdate()} */}
                            </div>

                        </div>
                        {renderLocation()}
                    </div>
                }
            </div>
        </Link >
    )
}
